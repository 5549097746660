import React from "react"
import { StaticQuery, graphql } from "gatsby"
import format from "date-fns/format"
import isValid from "date-fns/isValid"
import isPast from "date-fns/isPast"

import Layout from "../../components/layout"
import List from "../../components/list"
import ListItem from "../../components/list-item"
import styled from "styled-components"
import { colors } from "../../style/theme"
import ListItemLink from "../../components/list-item-link"

type Category =
  | "Course"
  | "Festival"
  | "Group meeting"
  | "Presentation"
  | string

const getCategoryColor = (category: Category) => {
  switch (category) {
    case "Course": {
      return colors.red
    }
    case "Festival": {
      return colors.green
    }
    case "Group meeting": {
      return colors.yellow
    }
    case "Presentation": {
      return colors.blue
    }

    default:
      return "inherit"
  }
}

export const eventsQuery = graphql`
  query EventsQuery {
    allMarkdownRemark(
      sort: {
        order: DESC
        fields: [
          frontmatter___startDate
          frontmatter___endDate
          frontmatter___title
        ]
      }
      filter: { frontmatter: { templateKey: { eq: "event" } } }
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            title
            startDate
            endDate
            category
            templateKey
          }
        }
      }
    }
  }
`

const EventTitle = styled.div`
  min-width: 50%;

  @media (max-width: 650px) {
    min-width: 100%;
  }
`
const EventCategory = styled.div<{ category: Category }>`
  margin-left: auto;
  color: ${props => getCategoryColor(props.category)};
  min-width: 20%;
  text-align: right;

  @media (max-width: 650px) {
    margin-left: 0;
    text-align: left;
    min-width: 100%;
  }
`
const EventDate = styled.div`
  margin-left: auto;
  min-width: 30%;
  text-align: right;

  @media (max-width: 650px) {
    margin-left: 0;
    text-align: left;
    min-width: 100%;
  }
`

type EventListProps = {
  events: {
    id: string
    slug: string
    title: string
    startDate: string
    endDate: string | null
    category: string
  }[]
}
const EventList = ({ events }: EventListProps) => {
  return (
    <List>
      {events.map(({ id, slug, title, startDate, endDate, category }) => {
        const start = new Date(startDate)

        const formattedStartDate = isValid(start)
          ? format(start, "do MMM yyyy")
          : null

        const end = endDate ? new Date(endDate) : null
        const formattedEndDate =
          end && isValid(end) ? format(end, "do MMM yyyy") : null

        const isMultipleDays =
          endDate && formattedEndDate !== formattedStartDate

        return (
          <ListItem key={`events-event-${id}`}>
            <EventTitle>
              <ListItemLink to={slug}>{title}</ListItemLink>
            </EventTitle>
            <EventDate>
              {formattedStartDate}
              {isMultipleDays && ` - ${formattedEndDate}`}
            </EventDate>
            <EventCategory category={category}>{category}</EventCategory>
          </ListItem>
        )
      })}
    </List>
  )
}

interface EventsProps {
  allMarkdownRemark: {
    edges: {
      node: {
        id: string
        fields: {
          slug: string
        }
        frontmatter: {
          title: string
          startDate: string
          endDate: string | null
          category: string
          templateKey: string
        }
      }
    }[]
  }
}
const Events: React.FC = () => (
  <StaticQuery
    query={eventsQuery}
    render={(props: EventsProps) => {
      const { allMarkdownRemark } = props
      const { edges } = allMarkdownRemark
      const allEventDetails = edges.map(edge => {
        const {
          node: {
            id,
            fields: { slug },
            frontmatter: { title, startDate, endDate, category },
          },
        } = edge

        return { id, slug, title, startDate, endDate, category }
      })

      const futureEvents = allEventDetails.filter(({ startDate }) => {
        try {
          const date = new Date(startDate)

          if (!isValid(date)) {
            return false
          }

          return !isPast(date)
        } catch (error) {
          return false
        }
      })

      const pastEvents = allEventDetails.filter(({ startDate }) => {
        try {
          const date = new Date(startDate)

          if (!isValid(date)) {
            return false
          }

          return isPast(date)
        } catch (error) {
          return false
        }
      })
      return (
        <Layout>
          <h1>Events</h1>
          <div>
            <h2>Upcoming events</h2>
            {futureEvents ? (
              <p>
                We&rsquo;ve got no events coming up, but watch this space, there
                will be more soon!
              </p>
            ) : (
              <EventList events={futureEvents} />
            )}
            <h2>Past events</h2>
            <EventList events={pastEvents} />
          </div>
        </Layout>
      )
    }}
  />
)

export default Events
